import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "../MainWebsite/shared/NavBar/NavBar";
import Footer from "../MainWebsite/shared/footer/Footer";
import Contact from "../MainWebsite/pages/contact/Contact";
import About from "../MainWebsite/pages/about/About";
import Home from "../MainWebsite/pages/home/Home";
import PageNotFound from "../PageNotFound/PageNotFound";
import ProjectDetails from "../MainWebsite/pages/ProjectDetails/ProjectDetails";
import Loading from "../MainWebsite/components/Loading/Loading";
import axios from "axios";

function MainRoutes() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [homePageProjects, setHomePageProjects] = useState([]);
  const [homePageLoading, setHomePageLoading] = useState(true);
  const isProjectPage = location.pathname.startsWith("/project/");
  const getFirstProjectName = () => {
    if (homePageProjects && homePageProjects.length > 0) {
      return homePageProjects[0].name;
    }
    return "";
  };
  // Fetch homepage projects
  useEffect(() => {
    const fetchHomePageProjects = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}api/Projects/HomePage`
        );
        const sortedProjects = response.data.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );
        setHomePageProjects(sortedProjects);
      } catch (error) {
        console.error("Error fetching homepage projects:", error);
      } finally {
        setHomePageLoading(false);
      }
    };

    fetchHomePageProjects();
  }, []);
  useEffect(() => {
    if (isProjectPage) {
      document.body.style.backgroundColor = "unset"; // This resets the background color
    }
  }, [isProjectPage, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (homePageLoading) {
    return <Loading />;
  }

  return (
    <>
      {(!isProjectPage || isMobile) && (
        <NavBar firstProjectName={getFirstProjectName()} />
      )}
      <Routes>
        <Route path="" element={<Home projects={homePageProjects} />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {homePageProjects != null &&
          homePageProjects.map((project) => {
            return (
              <Route
                key={project.sortOrder}
                path={`/project/${project.name
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
                element={<ProjectDetails projectName={project.name} />}
              />
            );
          })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!isProjectPage && <Footer />}
    </>
  );
}

export default MainRoutes;
