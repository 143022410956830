import React from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const location = useLocation(); // Get the current location

  const isHomePage = location.pathname === "/";
  const isAboutPage = location.pathname === "/about";
  const isContactPage = location.pathname === "/contact";

  const customClass =
    isHomePage || isAboutPage || isContactPage ? "mobilenav-corporate" : "";
  return (
    <div className="navbar-container">
      <div className="subnav">
        <Link to="/about">ABOUT</Link>
        <Link className="mainlogo" to="/">
          TAREK SABBOUH
        </Link>
        <Link to="/contact" className="letsconnectlink">
          LET’S CONNECT
        </Link>
      </div>
      <div className={`navbar-mobile-version ${customClass}`}>
        <Link to="/" className="logomobile">
          TAREK <br /> SABBOUH
        </Link>
        {!isAboutPage && !isContactPage && !isHomePage && (
          <div className="right-menu-bar">
            <Link to="/">
              <img src="/images/Smiley.webp" alt="Menu" className="menu-icon" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
